import React, { useMemo } from 'react';
import { useUserContext } from 'store/UserContext';
import saveRouteAndRedirectToLogin from 'utils/helpers/saveRouteAndRedirectToLogin';

export const AuthGuard: React.FC<any> = (props) => {
  const { children } = props;
  const { isLoggedIn } = useUserContext();

  const comp = useMemo(() => {
    if (!isLoggedIn) {
      saveRouteAndRedirectToLogin();
      return null;
    }

    return children;
  }, [isLoggedIn, children]);

  return comp;
};

export default AuthGuard;
