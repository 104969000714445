const academyLinks = {
  root: () => '/academy',
  whatIsTiledb: () => '/academy/what-is-tiledb',
  getStarted: () => '/academy/get-started/',
  structureImportance: () => '/academy/structure/why-structure-is-important/',
  arraysIntro: () => '/academy/structure/arrays/introduction/',
  tablesIntro: () => '/academy/structure/tables/introduction/',
  bioimagingIntro: () => '/academy/structure/life-sciences/biomedical-imaging/',
  ml: () => '/academy/structure/ai-ml/ml-models/introduction/',
  vectorSearch: () => '/academy/structure/ai-ml/vector-search/introduction/',
  populationGenomics: () =>
    '/academy/structure/life-sciences/population-genomics/introduction/',
  singleCell: () =>
    '/academy/structure/life-sciences/single-cell/introduction/',
};

export default academyLinks;
