import './NamespaceSelector.scss';
import React from 'react';
import { useWorkspaceContext } from 'store/WorkspaceContext';
import toggleNamespaceSelector from 'utils/helpers/toggleWorkspaceMenu';
import useGetOrganizations from 'hooks/api/Organizations/useGetOrganizations';
import Avatar from 'components/Avatar';

const NamespaceSelector: React.FC = () => {
  const { workspace } = useWorkspaceContext();
  const [, orgResponse] = useGetOrganizations();

  if (!workspace) {
    return null;
  }

  const organizations = orgResponse?.data || [];

  return (
    <button
      className="TDB-NamespaceSelector"
      onClick={() => toggleNamespaceSelector(true)}
      data-testid="namespace-selector"
      basic-tour-step="3"
    >
      <Avatar
        namespace={workspace.namespace}
        className="TDB-NamespaceSelector__avatar"
        logo={workspace.logo}
        size="small"
      />
      {organizations.length > 0 && (
        <div
          className="TDB-NamespaceSelector__sub-card"
          data-testid="multi-namespace-indicator"
        />
      )}
      {organizations.length > 1 && (
        <div
          className="TDB-NamespaceSelector__sub-card TDB-NamespaceSelector__sub-card--2"
          data-testid="multi-namespace-indicator-2"
        />
      )}
    </button>
  );
};

export default NamespaceSelector;
