import React from 'react';
import { useUserContext } from 'store/UserContext';
import useScrollPosition from '@react-hook/window-scroll';
import Button from 'components/v2/Button';
import IconBurger from 'components/Svg/icons/IconBurger';
import toggleMainSidebar from 'utils/helpers/toggleMainSidebar';
import useHeader from 'store/HeaderStore';
import AuthRender from 'components/AuthRender';
import { Link, useLocation } from 'react-router-dom';
import { authLinks, overviewLinks } from 'utils/links';
import saveRouteAndRedirectToLogin from 'utils/helpers/saveRouteAndRedirectToLogin';
import { useWorkspaceContext } from 'store/WorkspaceContext';
import InboxButton from './components/inbox/InboxButton';
import classNames from 'classnames';
import styles from './Header.module.scss';
import Breadcrumb from 'components/v2/Breadcrumb';
import './Header.scss';

const Header: React.FC = () => {
  const { isLoggedIn } = useUserContext();
  const scrollY = useScrollPosition(60 /*fps*/);
  const headerContent = useHeader((state) => state.HeaderContent);
  const { variant } = headerContent;
  const { workspace } = useWorkspaceContext();
  const isTransparentVariant = variant === 'transparent';
  const { pathname } = useLocation();
  const isOverviewRoute = pathname === overviewLinks.root();

  return (
    <>
      <header
        className={classNames(
          'TDB-Header',
          classNames({
            'TDB-Header--floating': scrollY > 0,
            'TDB-Header--transparent': isTransparentVariant,
          })
        )}
      >
        <div className="TDB-Header__Title">
          <Button
            className={styles.burger}
            size="medium"
            variant="secondary"
            icon={IconBurger}
            onClick={(): void => {
              toggleMainSidebar(true);
            }}
            dataTestID="main-sidebar-burger-button"
          />
          {!isTransparentVariant && !isOverviewRoute && <Breadcrumb />}
        </div>
        {headerContent.render && (
          <div className="TDB-Header__Children">
            {headerContent.render({ isLoggedIn })}
          </div>
        )}
        <div className="TDB-Header__aside">
          <AuthRender inverse>
            <div className="d-flex">
              <Link
                to={authLinks.login()}
                onClick={(e) => {
                  /**
                   * When the user clicks on "Login" we save the current path and redirect them there after a successful login
                   */
                  e.preventDefault();
                  saveRouteAndRedirectToLogin();
                }}
                className="mr-2"
              >
                <Button size="medium" variant="outlined">
                  Log in
                </Button>
              </Link>
              <Link to={authLinks.signup()}>
                <Button size="medium" variant="primary">
                  Sign up
                </Button>
              </Link>
            </div>
          </AuthRender>
          <AuthRender>{workspace && <InboxButton />}</AuthRender>
        </div>
      </header>
    </>
  );
};

export default Header;
