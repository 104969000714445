import { OnboardingItem } from 'api-client';
import isEnterpriseEnv from 'utils/helpers/isEnterpriseEnv';

export enum OnboardingFlow {
  Intro = 'user.intro',
  ProfileSetup = 'user.profile_setup',
  BasicTour = 'user.basic_tour',
  LaunchNotebook = 'user.launch_notebook',
  IndicateOnboardingFinish = 'user.indicate_onboarding_finish',
  OrganizationBasicTour = 'organization.basic_tour',
  IndicateOrganizationOnboardingFinish = 'organization.indicate_onboarding_finish',
}
export const welcomeOnboardingItems: OnboardingItem[] = [OnboardingItem.Intro];
export const profileSetupOnboardingItems: OnboardingItem[] = [
  OnboardingItem.ThemeSelection,
  OnboardingItem.ProfileInfo,
  // We skip "Claim Credits" step for enterprise releases, since billing is disabled.
  ...(isEnterpriseEnv() ? [] : [OnboardingItem.ClaimCredits]),
];

export const basicTourOnboardingItems: OnboardingItem[] = [
  OnboardingItem.BasicTour,
];
export const launchNotebookOnboardingItems: OnboardingItem[] = [
  OnboardingItem.LaunchNotebook,
];
export const indicateOnboardingFinishItems: OnboardingItem[] = [
  OnboardingItem.IndicateFinishUserOnboarding,
];
export const organizationBasicTourOnboardingItems: OnboardingItem[] = [
  OnboardingItem.OrganizationBasicTour,
];
export const indicateOrganizationOnboardingFinishItems: OnboardingItem[] = [
  OnboardingItem.IndicateFinishOrgOnboarding,
];

const onboardingFlows = {
  user: {
    intro: OnboardingFlow.Intro,
    profileSetup: OnboardingFlow.ProfileSetup,
    basicTour: OnboardingFlow.BasicTour,
    launchNotebook: OnboardingFlow.LaunchNotebook,
    indicateOnboardingFinish: OnboardingFlow.IndicateOnboardingFinish,
  },
  organization: {
    basicTour: OnboardingFlow.OrganizationBasicTour,
    indicateOnboardingFinish:
      OnboardingFlow.IndicateOrganizationOnboardingFinish,
  },
};

/**
 * Helper function to get the list of onboarding items for a given flow
 *
 * @param flow the onboarding flow
 * @returns onboarding items that the given flow consists of
 */
export const getOnboardingItemsByFlow = (flow: OnboardingFlow) => {
  switch (flow) {
    case OnboardingFlow.Intro:
      return welcomeOnboardingItems;
    case OnboardingFlow.ProfileSetup:
      return profileSetupOnboardingItems;
    case OnboardingFlow.BasicTour:
      return basicTourOnboardingItems;
    case OnboardingFlow.LaunchNotebook:
      return launchNotebookOnboardingItems;
    case OnboardingFlow.IndicateOnboardingFinish:
      return indicateOnboardingFinishItems;
    case OnboardingFlow.OrganizationBasicTour:
      return organizationBasicTourOnboardingItems;
    case OnboardingFlow.IndicateOrganizationOnboardingFinish:
      return indicateOrganizationOnboardingFinishItems;
    default:
      return [];
  }
};

export default onboardingFlows;
