import * as React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import './Typography.scss';

export const SIZE_VARIANTS = [
  'heading-xxl',
  'heading-xl',
  'heading-lg',
  'heading-md',
  'heading-sm',
  'heading-xs',
  'body-xl',
  'body-lg',
  'body-lg-text',
  'body-md',
  'body-md-text',
  'body-sm',
  'body-sm-text',
  'body-xs',
  'body-xs-text',
  'body-xxs',
] as const;

export type SizeVariants = (typeof SIZE_VARIANTS)[number];

export const VARIANTS = [
  'default',
  'disabled',
  'app-default',
  'app-selected',
  'tertiary',
  'link',
  'secondary',
  'on-fill',
  'on-fill-brand',
  'on-surface-info',
] as const;

export type TypographyVariants = (typeof VARIANTS)[number];

export const WEIGHTS = ['400', '500', '600', '700', '800'] as const;

export type FontWeight = (typeof WEIGHTS)[number];

export interface TypographyProps extends React.HTMLProps<HTMLParagraphElement> {
  fontSize?: SizeVariants;
  fontWeight?: FontWeight;
  variant?: TypographyVariants;
  className?: string;
  as?: keyof JSX.IntrinsicElements;
  to?: string;
  align?: 'left' | 'center' | 'right';
  hoverEffect?: 'underline';
  dataTestId?: string;
  dataCy?: string;
  ellipsis?: boolean;
  noWrap?: boolean;
  basicTourStep?: string;
  organizationBasicTourStep?: string;
}

const Typography: React.FC<TypographyProps> = ({
  children,
  className,
  as = 'span',
  to,
  variant,
  fontWeight,
  align,
  href,
  style,
  hoverEffect,
  fontSize,
  dataTestId,
  dataCy,
  ellipsis,
  noWrap,
  basicTourStep,
  organizationBasicTourStep,
  ...rest
}) => {
  const elementRef = React.useRef<any>();
  const Component: keyof JSX.IntrinsicElements | React.FC<any> = to ? Link : as;
  const typographyVariant: TypographyVariants = React.useMemo(() => {
    if (variant) {
      return variant;
    }
    // If it's link
    if (as === 'a' || to) {
      return 'link';
    }

    return 'default';
  }, [variant, as, to]);

  const textIsTruncated = React.useMemo(() => {
    const element = elementRef.current;
    if (!ellipsis || !element) {
      return false;
    }

    if (element.clientWidth < element.scrollWidth) {
      return true;
    }

    return false;
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [ellipsis, elementRef.current]);

  return (
    <Component
      ref={elementRef}
      data-testid={dataTestId}
      data-cy={dataCy}
      style={style}
      title={textIsTruncated ? children : null}
      className={classNames('TDB-Typography', className, {
        'TDB-Typography--ellipsis': ellipsis,
        'TDB-Typography--truncated': textIsTruncated,
        'TDB-Typography--no-wrap': noWrap,
        [`TDB-Typography--${fontSize}`]: fontSize,
        [`TDB-Typography--${typographyVariant}`]: typographyVariant,
        [`TDB-Typography--fw-${fontWeight}`]: fontWeight,
        [`TDB-Typography--hover-${hoverEffect}`]: hoverEffect,
        [`TDB-Typography--align-${align}`]: align,
      })}
      to={to}
      href={href}
      basic-tour-step={basicTourStep}
      organization-basic-tour-step={organizationBasicTourStep}
      {...rest}
    >
      {children}
    </Component>
  );
};

export default Typography;
