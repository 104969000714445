import { useCallback } from 'react';
import { FormikHelpers } from 'formik';
import { AxiosResponse, AxiosError, AxiosPromise } from 'axios';
import capitalizeFirstLetter from 'utils/helpers/string/capitalizeFirstLetter';
import { identity } from 'ramda';
import {
  QueryObserverResult,
  QueryOptions,
  RefetchOptions,
  UseMutateAsyncFunction,
  UseQueryOptions,
} from '@tanstack/react-query';
import sessionStorageKeys from 'utils/constants/sessionStorageKeys';
import useSnackbar, { ShowSnackbarType } from 'store/SnackbarStore';
import saveRouteAndRedirectToLogin from 'utils/helpers/saveRouteAndRedirectToLogin';
import { useUserContext } from 'store/UserContext';
import history from 'utils/helpers/history';
import { authLinks } from 'utils/links';
import logoutHandler from 'utils/helpers/logoutHandler';

/**
 * Golang codes to force hide snackbar
 */
export const noSnackbarErrors: number[] = [
  481, // 481, 482, 4004 codes mean the user has not confirmed their email
  482, // 481, 482, 4004 codes mean the user has not confirmed their email
  4004, // 481, 482, 4004 codes mean the user has not confirmed their email
];

/**
 * The type of the params of the mutation requests
 */
export type MutationFunctionParams<T> = [T, ApiRequestConfig<T>] | [T];

/**
 * Same type as a mutation function but it doesn't return anything,
 * Used in components with callbacks such as modals,
 * that call Mutations and return void instead of the promise
 */
export type MutationFunctionRetunVoid<T> = (
  data: MutationFunctionParams<T>
) => void;

export interface ApiRequestConfig<T> {
  /**
   * If it should NOT show snackbar after request.
   */
  noSnackbar?: boolean;
  /**
   * Formik helpers.
   */
  helpers?: FormikHelpers<T>;
  /**
   * Function to transform data from the api to have
   * the same structure like the ones in the form.
   */
  mapDataToFormValues?: (v: T) => any;
}
/**
 * MutateFn is the mutateAsync that the useMutation returns.
 * More info  https://react-query.tanstack.com/reference/useMutation
 */
type MutateFn<T, F> = UseMutateAsyncFunction<F, unknown, T, unknown>;
/**
 * MutationFnRequest is the result after we wrap react-query's "mutateAsync"
 * with our useMutateRequest helper hook
 */
export type MutationFnRequest<T, F> = (
  vars: MutationFunctionParams<T>
) => Promise<void | AxiosResponse<F>>;

export type MutateResponse<T, F> = [
  MutationFnRequest<T, F>,
  AxiosResponse<F> | undefined,
  null | Error,
  boolean
];

export function useMutateRequest<Params, Resp>(
  apiFunc: MutateFn<Params, Resp>
) {
  const { isLoggedIn } = useUserContext();
  const showSnackbar = useSnackbar((state) => state.showSnackbar);
  const mutateRequest = useCallback(
    (params: MutationFunctionParams<Params>) => {
      const [data, config = {}] = params;

      config.helpers?.setSubmitting(true);
      return apiFunc(data)
        .then(onMutateSubmit<Params, Resp>(config, data))
        .catch(onCatch(config, showSnackbar, isLoggedIn));
    },
    [apiFunc, showSnackbar, isLoggedIn]
  );

  return mutateRequest;
}

const onMutateSubmit =
  <T, F>(config: ApiRequestConfig<T> | undefined, data: T) =>
  (res: F) => {
    // Update formik props for loading indicator
    const dataMapper = config?.mapDataToFormValues || identity;
    config?.helpers?.setSubmitting(false);
    const transformedData = dataMapper(data);
    // Reset form status but keep data
    config?.helpers?.resetForm({ values: transformedData });

    return res;
  };

export type RefetchType<T> = (
  options?: RefetchOptions
) => Promise<QueryObserverResult<T>>;

export type UseRequestType<T, F> = (
  queryData: T,
  config?: ApiRequestConfig<T> | undefined,
  queryConfig?: UseQueryOptions<AxiosResponse<F>, any, AxiosResponse<F>>
) => [
  RefetchType<AxiosResponse<F>>,
  AxiosResponse<F> | undefined,
  unknown,
  boolean
];

export type QueryRequestType<T, F> = (
  data: QueryOptions<T, unknown, F>
) => AxiosPromise<F>;

export function useQueryRequest<Params, Resp>(
  apiFunc: (data: Params) => AxiosPromise<Resp>,
  config: ApiRequestConfig<any> = {}
) {
  const { isLoggedIn } = useUserContext();
  const showSnackbar = useSnackbar((state) => state.showSnackbar);
  /**
   * Function that will do the actual request to the api
   * It will update the state automatically with response and error
   */
  const callRequest = useCallback<QueryRequestType<Params, Resp>>(
    ({ queryKey }): AxiosPromise<Resp> => {
      // Update formik props for loading indicator
      config.helpers?.setSubmitting(true);
      const [, data] = queryKey as any[];

      // Make request by calling the api function
      return apiFunc(data)
        .then(onSubmit(config, data))
        .catch(onCatch(config, showSnackbar, isLoggedIn)) as AxiosPromise<Resp>;
    },
    [apiFunc, config, showSnackbar, isLoggedIn]
  );

  return callRequest;
}

const onSubmit =
  <T, F>(config: ApiRequestConfig<T> | undefined, data?: T) =>
  (res: AxiosResponse<F>) => {
    // Update formik props for loading indicator
    const dataMapper = config?.mapDataToFormValues || identity;
    config?.helpers?.setSubmitting(false);
    const transformedData = dataMapper(data as T);
    // Reset form status but keep data
    config?.helpers?.resetForm({ values: transformedData });

    return res;
  };

const onCatch =
  (
    config: ApiRequestConfig<any>,
    showSnackbar: ShowSnackbarType,
    isLoggedIn: boolean
  ) =>
  (ex: AxiosError): void => {
    const { response } = ex;
    let { noSnackbar = false } = config;
    // Update formik props for loading indicator
    config?.helpers?.setSubmitting(false);
    /**
     * Force-hide snackbar on specific golang codes
     */
    if (
      response?.data?.code &&
      noSnackbarErrors.includes(response?.data?.code)
    ) {
      noSnackbar = true;
    }

    /**
     * Force logout on 401 status
     * We use setTimeout to call it asynchronously, otherwise "react-query"
     * will throw a cancelled Error instead of the original error object
     */
    if (response?.status === 401) {
      if (isLoggedIn) {
        // if user is logged in, redirect them to logout page which handles all logout logic
        history.push(authLinks.logout());
      } else {
        /**
         * If user is not logged in clear everything ui-side.
         *
         * This function call is important to log the user out from jupyterhub.
         *
         * If the user is not logged in but already have valid jupyterhub cookies,
         * they may log in with a different account to tiledb cloud, and then log in to the previous user's jupyterhub instance.
         * To prevent this we need to clear jh cookies on a 401 to make sure they reset after a new cloud login.
         */
        logoutHandler();
      }
      // Don't show snackbar for 401 since we show a warning in Signin page
      sessionStorage.setItem(sessionStorageKeys.INVALID_TOKEN, 'true');
      noSnackbar = true;
    }

    /**
     * If the user is NOT logged in and receives a 403, redirect them to login page
     * We treat 403 as a 401 in this case for UX reasons
     */
    if (response?.status === 403 && !isLoggedIn) {
      saveRouteAndRedirectToLogin();
      noSnackbar = true;
    }

    let message;
    const data = response?.data;
    if (response?.status === 500) {
      /**
       * If status is 500 and there is a message field in the payload it means that the error is handled
       * and that we should show that message to the user
       */
      if (typeof data === 'object' && data.message) {
        message = data.message;
      }
    } else {
      if (typeof data === 'string') {
        message = data;
      } else if (typeof data === 'object' && data.message) {
        message = data.message;
      }
    }
    /**
     * Message for request timeout
     */
    if (ex.code === 'ECONNABORTED') {
      message =
        'Could not connect to the server. Please check your internet connection.';
    }

    message = capitalizeFirstLetter(message);

    !noSnackbar &&
      showSnackbar({
        title: message || 'Unexpected error occurred',
        variant: 'error',
      });
    throw ex;
  };
