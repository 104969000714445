import './Tour.scss';
import React, { useMemo, useState } from 'react';
import Joyride, { Step, TooltipRenderProps } from 'react-joyride';
import useTimeout from 'hooks/useTimeout';
import Text from 'components/Text';
import Button from 'components/v2/Button';
import IconClose from 'components/Svg/icons/IconClose';

interface TooltipProps extends TooltipRenderProps {
  /**
   * Callback func when user clicks on (x) button
   */
  onDismiss: () => void;

  /**
   * Enable the dismiss functionality
   */
  dismissable: boolean;

  /**
   * Set dismiss request state (render dismiss step)
   */
  isDismissRequested: boolean;

  /**
   * Callback for actual dismiss (click on confirm button)
   */
  onDismissConfirm?: () => void;

  /**
   * Callback to revert the dismiss request state (click on cancel button)
   */
  onCancelDismiss: () => void;
}

const Tooltip = (props: TooltipProps) => {
  const {
    continuous,
    index,
    step,
    primaryProps,
    tooltipProps,
    size,
    dismissable,
    onDismiss,
    isDismissRequested,
    onDismissConfirm,
    onCancelDismiss,
  } = props;

  return (
    <div
      className="TDB-Tour__tooltip"
      {...tooltipProps}
      data-testid="tour-tooltip"
    >
      <div className="TDB-Tour__tooltip__header">
        {step.title && (
          <Text
            className="TDB-Tour__tooltip__title"
            variant="body-lg"
            color="text-action-alternative"
            fontWeight="semi-bold"
          >
            {step.title}
          </Text>
        )}
        {dismissable && (
          <button
            className="TDB-Tour__tooltip__close-btn"
            onClick={onDismiss}
            data-cy="skip-tour-button"
          >
            <IconClose size={16} fill="var(--text-action-alternative)" />
          </button>
        )}
      </div>
      <Text variant="subtext" color="text-action-alternative">
        {step.content}
      </Text>
      <div className="TDB-Tour__tooltip__footer">
        {/**
         * Show tour length indicator only if steps are > 1
         * Hide the indicator if current step is replaced by the "dismiss confirmation step"
         */}
        {size > 1 && !isDismissRequested ? (
          <Text variant="subtext" color="text-action-alternative">
            {index + 1} of {size} tips
          </Text>
        ) : (
          <div />
        )}
        {/**
         * Show next button if continuous is set to true
         * Next button's text is "Got it" on last step
         * Hide this button if we are in a "dimiss confirmation" state
         */}
        {continuous && !isDismissRequested && (
          <Button
            onClick={primaryProps.onClick}
            ariaLabel={primaryProps['aria-label']}
            size="medium"
            variant="primary"
          >
            {index + 1 === size ? 'Got it' : 'Next'}
          </Button>
        )}
        {/**
         * Custom footer for the "dismiss confirmation" state
         */}
        {isDismissRequested && (
          <div className="TDB-Tour__tooltip__footer__dismiss-items">
            <Button size="medium" onClick={onCancelDismiss} variant="secondary">
              Cancel
            </Button>
            <Button
              onClick={onDismissConfirm}
              ariaLabel={primaryProps['aria-label']}
              size="medium"
              variant="primary"
            >
              Yes, dismiss
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

interface TourProps {
  /**
   * Tour steps
   */
  steps: Step[];

  /**
   * Active state of tour component (it is controlled)
   */
  active: boolean;

  /**
   * Optional callback when the tour has finished
   */
  onFinish?: () => void;

  /**
   * Optional callback when the tour has been dismissed early
   */
  onDismiss?: () => void;

  /**
   * Optionally start the tour after x time in miliseconds
   */
  startWithDelay?: number;

  /**
   * Enable the dismiss functionality
   */
  dismissable: boolean;
}

const Tour: React.FC<TourProps> = (props) => {
  const {
    steps,
    active,
    onFinish,
    onDismiss,
    startWithDelay = 0,
    dismissable,
  } = props;
  const [stepIndex, setStepIndex] = useState(1000);
  const [dismissRequested, setDismissRequested] = useState(false);

  useTimeout(
    () => {
      setStepIndex(0);
    },
    startWithDelay,
    []
  );

  const localSteps = useMemo(() => {
    /**
     * Hack to implement the "dismiss confirmation" functionality
     * Upon dismiss, we want to replace the step in-place with a custom confirmation content
     */
    const s = [...steps];
    if (dismissRequested) {
      /**
       * Replace current step with the confirmation step
       * All props should remain the same, except the title and content
       */
      s[stepIndex] = {
        ...steps[stepIndex],
        title: 'Are you sure you want to skip?',
        content:
          "Skipping the basic tour will mark it as complete and you won't have the change to see it again. Are you sure you want to skip it?",
      };
    }

    // Otherwise return the steps from props as they are
    return s;
  }, [steps, stepIndex, dismissRequested]);

  const handleEvent = (e: any) => {
    if (e.action === 'next' && e.lifecycle === 'complete') {
      /**
       * Step navigation is controlled
       */
      setStepIndex(e.index + 1);
    }
    if (e.status === 'finished') {
      /**
       * call onFinish callback (if any) when the tour has finished
       */
      onFinish?.();
    }
  };

  const requestDismiss = () => {
    /**
     * This will replace the current step with the custom "dismiss confirmation" step
     */
    setDismissRequested(true);
  };

  const onCancelDismiss = () => {
    /**
     * This will replace the "dismiss confirmation" step with the original step
     */
    setDismissRequested(false);
  };

  return (
    <Joyride
      steps={localSteps}
      stepIndex={stepIndex}
      run={active}
      showProgress={!dismissRequested}
      continuous
      disableOverlay
      disableCloseOnEsc
      callback={handleEvent}
      tooltipComponent={(props) => (
        <Tooltip
          {...props}
          dismissable={dismissable}
          onDismiss={requestDismiss}
          isDismissRequested={dismissRequested}
          onDismissConfirm={onDismiss}
          onCancelDismiss={onCancelDismiss}
        />
      )}
      styles={{
        options: {
          arrowColor: 'var(--tooltip)',
          backgroundColor: 'var(--tooltip)',
          overlayColor: 'rgba(79, 26, 0, 0.4)',
          primaryColor: 'var(--tooltip)',
          textColor: 'var(--text-action-alternative)',
          width: 296,
          zIndex: 1000,
        },
      }}
    />
  );
};

export default Tour;
