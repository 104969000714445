import React, { useState } from 'react';
import { Placement } from 'react-joyride';
import Tour from 'components/Tour';
import { useOnboardingContext } from 'store/OnboardingContext';
import useAddCompletedOnboardingItems from 'hooks/api/Onboarding/useAddCompletedOnboardingItems';
import { useWorkspaceContext } from 'store/WorkspaceContext';
import { OnboardingItem } from 'api-client';

const steps = [
  {
    target: '[basic-tour-step="0"]',
    title: 'Manage all your assets',
    content:
      'Store, catalog, and govern all your data and code in a single data platform. TileDB has all the functionality you need to build a single infrastructure for your organization.',
    placement: 'right' as Placement,
  },
  {
    target: '[basic-tour-step="1"]',
    title: 'Control your compute',
    content:
      'Launch, manage, and monitor your servers, while reviewing your activity log for streamlined operations.',
    placement: 'right' as Placement,
  },
  {
    target: '[basic-tour-step="2"]',
    title: 'Explore public assets',
    content:
      'Discover and access a selection of public assets in our marketplace. Browse, select, and utilize resources that can propel your projects forward.',
    placement: 'right' as Placement,
  },
  {
    target: '[basic-tour-step="3"]',
    title: 'Switch organization',
    content:
      'Effortlessly toggle between the different organizations in your account.',
    placement: 'right' as Placement,
  },
];

const BasicTour = () => {
  const { workspace } = useWorkspaceContext();
  const { refetchCompletedOnboardingItems } = useOnboardingContext();
  const [addOnboardingItems] = useAddCompletedOnboardingItems();
  const [active, setActive] = useState(true);
  const addOnboardingItemsData = {
    namespace: workspace?.namespace as string,
    items: {
      entries: [OnboardingItem.BasicTour],
    },
  };

  const onFinish = async () => {
    await addOnboardingItems([addOnboardingItemsData]);
    refetchCompletedOnboardingItems();
  };

  const onDismiss = async () => {
    setActive(false);
    await addOnboardingItems([addOnboardingItemsData]);
    refetchCompletedOnboardingItems();
  };

  return (
    <Tour
      steps={steps}
      active={active}
      onFinish={onFinish}
      onDismiss={onDismiss}
      startWithDelay={1000}
      dismissable
    />
  );
};

export default BasicTour;
