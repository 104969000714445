import useMount from 'hooks/useMount';
import React from 'react';
import useModal from 'store/ModalStore';
import { useOnboardingContext } from 'store/OnboardingContext';

const GetStarted: React.FC = () => {
  const {
    refetchCompletedOnboardingItems,
    isIntroComplete,
    isProfileSetupComplete,
  } = useOnboardingContext();
  const showWelcomeModal = useModal((state) => state.showWelcomeModal);
  const showProfileSetupModal = useModal(
    (state) => state.showProfileSetupModal
  );
  const flushModals = useModal((state) => state.flushModals);
  useMount(() => {
    if (!isIntroComplete) {
      showWelcomeModal({
        onSuccess: () => {
          flushModals();
          if (!isProfileSetupComplete) {
            showProfileSetupModal({
              onSuccess: () => {
                refetchCompletedOnboardingItems();
                flushModals();
              },
            });
          }
        },
      });
    } else if (!isProfileSetupComplete) {
      showProfileSetupModal({
        onSuccess: () => {
          refetchCompletedOnboardingItems();
          flushModals();
        },
      });
    }
  });

  return null;
};

export default GetStarted;
