import sessionStorageKeys from 'utils/constants/sessionStorageKeys';
import history from 'utils/helpers/history';
import { authLinks } from 'utils/links';

const saveRouteAndRedirectToLogin = () => {
  const { pathname } = history.location;
  if (!pathname.startsWith(authLinks.root())) {
    // Save the route only if it is not an auth page (i.e. the login page)
    // Redirecting to an auth page doesn't make sense.
    // Users with an auth page saved in session storage might get stuck in login page.
    sessionStorage.setItem(
      sessionStorageKeys.REDIRECT_TO_AFTER_LOGIN,
      pathname
    );
  }
  history.replace(authLinks.login());
};

export default saveRouteAndRedirectToLogin;
