const arrayLinks = {
  details: ({ namespace, arrayId }: { namespace: string; arrayId: string }) =>
    `/arrays/details/${namespace}/${arrayId}`,

  overview: ({ namespace, arrayId }: { namespace: string; arrayId: string }) =>
    `/arrays/details/${namespace}/${arrayId}/overview`,

  sharing: ({ namespace, arrayId }: { namespace: string; arrayId: string }) =>
    `/arrays/details/${namespace}/${arrayId}/sharing`,

  pricing: ({ namespace, arrayId }: { namespace: string; arrayId: string }) =>
    `/arrays/details/${namespace}/${arrayId}/pricing`,

  activity: ({ namespace, arrayId }: { namespace: string; arrayId: string }) =>
    `/arrays/details/${namespace}/${arrayId}/activity`,

  metadata: ({ namespace, arrayId }: { namespace: string; arrayId: string }) =>
    `/arrays/details/${namespace}/${arrayId}/metadata`,

  schema: ({ namespace, arrayId }: { namespace: string; arrayId: string }) =>
    `/arrays/details/${namespace}/${arrayId}/schema`,

  settings: ({ namespace, arrayId }: { namespace: string; arrayId: string }) =>
    `/arrays/details/${namespace}/${arrayId}/settings`,
};

export default arrayLinks;
