import { OrganizationRoles } from 'api-client';
import { isString } from 'utils/helpers/string';

const normalizeOrganizationRole = (role: OrganizationRoles): string => {
  if (!isString(role)) {
    return '';
  }

  if (role === OrganizationRoles.Admin) {
    return 'ADMIN';
  } else if (role === OrganizationRoles.ReadOnly) {
    return 'READ';
  } else if (role === OrganizationRoles.ReadWrite) {
    return 'READ + WRITE';
  } else if (role === OrganizationRoles.Owner) {
    return 'OWNER';
  }

  return '';
};

export default normalizeOrganizationRole;
